@use '../../styles/global.scss';

.section {
  flex-wrap: nowrap;
  background-color: #f1f1f1;

  .text {
    max-width: 50%;
    min-width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .section {
    flex-wrap: wrap;

    .text {
      width: 100%;
      max-width: none;
      margin: 3vw 3vw 0;
    }
  }
}
