@use '../../../styles/global.scss';

.section {
  width: 95vw;
  max-width: 900px;
  height: fit-content;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;

  h2 {
    color: white;
  }

  .slider {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    .slide {
      height: fit-content;
      padding: 0 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Barlow, sans-serif;
      font-size: clamp(1rem, 1.5vw, 1rem);
      font-weight: 400;
      line-height: 26px;
      text-align: center;

      p {
        width: 100%;
        height: 110px;
        overflow-y: scroll;
        margin-bottom: 30px;
        padding: 0 10px;
        text-align: justify;
        color: white;
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #00bd97;
      }

      .cliente {
        display: flex;
        align-items: center;
        gap: 1rem;

        .image {
          width: 60px;
          height: 60px;

          img {
            border-radius: 50%;
          }
        }
      }

      .infoClient {
        display: flex;
        flex-direction: column;
        text-align: left;

        span:nth-child(1) {
          font-weight: 600;
        }
      }
    }

    div:nth-child(2),
    div:nth-child(3) {
      button {
        width: 50px;
        height: 50px;
        margin: 0 0.5vw;
        margin-top: -80%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #00bd97;
        border-radius: 50%;
        font-size: 30px;
        font-weight: 300;
        text-decoration: none;
        color: #00bd97;
        background-color: rgb(0 0 0 / 40%);
        cursor: pointer;

        &:active {
          top: 1px;
          position: relative;
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }
}
