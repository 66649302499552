.item {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 5px;

  h3 {
    height: 100px;
    margin: 0;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: Khand, sans-serif;
    font-size: 1rem;
    text-align: left;
    color: white;
    background-color: rgb(0 189 151);
  }

  p {
    height: 90px;
    margin: 0;
    padding: 10px;
    font-family: Barlow, sans-serif;
    font-size: clamp(0.8rem, 1vw, 1.5vw);
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #353e52;
    background-color: #f1f1f1;
  }

  a {
    width: 250px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-decoration: none;
    color: white;
    background-color: #00bd97;

    span {
      margin: 0 10px;
      font-size: 0.8rem;
    }
  }
}
