@use '../../styles/global.scss';

.section {
  max-width: 90vw;
  padding: 0;
  flex-direction: column;
  align-items: center;

  h2 {
    color: white;
  }

  span {
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    text-decoration-line: underline;
    text-decoration-color: #00bd97;
  }
}
