.bgfale {
  padding: 80px 0 160px;
  background-position: left;
  background-repeat: no-repeat;
}

.titulofalemob {
  margin-bottom: 50px;
  font-family: Khand, sans-serif;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  color: #353e52;
}

.textdec {
  text-decoration-line: underline;
  text-decoration-color: #00bd97;
}

.inputform {
  width: 100%;
  margin-bottom: 28px;
  padding: 5px 5px 5px 15px;
  border: none;
  border-bottom: solid;
  font-family: Barlow, sans-serif;
  font-weight: 400;
  color: #000;
  background-color: transparent;
  border-width: 2px;
  border-color: rgb(53 62 82 / 37%);
  outline: none;
}

.buttonenviar {
  margin-top: 30px;
  padding: 12px;
  border-radius: 2px;
  font-family: Barlow, sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background-color: #00bd97;
  cursor: pointer;
}

@media (max-width: 600px) {
  .padding {
    padding: 0;
  }
}

@media (min-width: 600px) {
  .padding {
    padding: 0 200px;
  }
}

.section {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 600px) {
  .section {
    width: 375px;

    .titulofale {
      margin-top: -30px;
      margin-bottom: -10px;
      font-family: Khand, sans-serif;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      color: #353e52;
    }
  }
}

@media (min-width: 600px) {
  .section {
    width: 1300px;

    .titulofale {
      margin-bottom: -10px;
      font-family: Khand, sans-serif;
      font-size: 50px;
      font-weight: 500;
      text-align: center;
      color: #353e52;
    }
  }
}
