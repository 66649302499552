.item {
  h3 {
    width: 200px;
    height: 50px;
    margin: 0;
    padding: 25px;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: Khand, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: white;
    background-color: rgb(0 189 151);
    cursor: pointer;
  }

  div {
    width: 200px;
    height: 233px;
    cursor: pointer;
  }

  a {
    width: 200px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    text-decoration: none;
    color: white;
    background-color: #00bd97;
    cursor: pointer;

    span {
      margin: 0 10px;
      font-size: 0.8rem;
    }
  }
}
