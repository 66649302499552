.bannerContainer {
  width: 100%;
  min-height: 35vh;
  padding: 8vh 5vw;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
}

.childrenContainer {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2 {
    text-align: center;
    color: #fff;
  }

  h1 {
    font-size: clamp(1.5rem, 8vw, 3rem);
  }

  h2 {
    font-size: clamp(1.2rem, 6.5vw, 2.5rem);
  }
}
