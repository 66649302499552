.section {
  max-width: 100%;
  position: relative;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;

  .carouselContainer {
    display: flex;
    flex-wrap: nowrap;
  }

  .carouselSlot {
    margin-right: 20px;
    flex: 1 0 100%;
    flex-basis: 50px;
  }

  .slideButtonContainer {
    width: 100%;
    top: 45%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 3vw;
    height: 3vw;
    margin: 0 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00bd97;
    border-radius: 50%;
    font-size: 3vw;
    font-weight: 300;
    text-decoration: none;
    color: #00bd97;
    background-color: rgb(0 0 0 / 40%);
    cursor: pointer;

    &:active {
      top: 1px;
      position: relative;
    }

    @media only screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  .paper {
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
  }

  .wrapper {
    width: 100%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .containerComponent {
    .carouselSlot {
      margin-right: 10px;
    }
  }
}
