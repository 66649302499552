@use '../../styles/global.scss';

.section {
  padding: 0;

  .lineDecorCenter {
    width: 25vw;
    height: 2px;
    margin-top: 15vh;
    margin-left: -70vw;
    position: absolute;
    background-color: #00bf98;
  }

  .image {
    width: 40vw;
    min-width: 35vw;
    height: fit-content;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 30%);
  }

  .text {
    width: 40vw;
    margin-left: 3vw;
    text-align: left;
    color: white;

    h2 {
      color: white;
    }

    p {
      color: white;
    }
  }

  .destaque {
    width: 70%;
    margin-top: 3vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    color: white;

    .icon {
      max-width: 15vw;
      margin: 10px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: white;
      }

      p {
        color: white;
      }
    }

    .lineDecor {
      width: 15vw;
      height: 2px;
      align-self: center;
      background-color: #00bf98;
    }

    .columnDecor {
      width: 5px;
      height: 55vh;
      margin-top: -55vh;
      align-self: center;
      background-color: #00bf98;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    .image {
      width: 100% !important;
      margin-bottom: 3rem;
    }

    .text {
      width: 100% !important;
      margin: 0;
    }

    .destaque {
      width: 100% !important;
      justify-content: space-around !important;

      .columnDecor,
      .lineDecor {
        display: none;
      }
    }
  }
}
