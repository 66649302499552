@use '../../styles/global.scss';

.section {
  .text {
    width: 40vw;
    margin-right: 3rem;

    .info {
      display: flex;

      .itemInfo {
        width: fit-content;
        margin: 0 1rem;

        span {
          margin-top: 2rem;
          margin-bottom: 0;
          font-family: Khand, sans-serif;
          font-size: 2.5rem;
          font-weight: 500;
          color: #353e52;
        }

        h3 {
          margin-top: -10px;
          font-family: Barlow, sans-serif;
          font-size: 15px;
          font-weight: 100;
        }
      }
    }
  }

  .image {
    width: 40vw;
    height: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 30%);

    img {
      top: 40%;
      left: 40%;
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    .text {
      width: 95vw;
      margin: 3vw;
      margin-bottom: 3rem;
    }

    .image {
      width: 95vw;
    }
  }
}
