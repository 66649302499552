.buttonContainer {
  height: fit-content;

  span {
    padding: 2px 15px 3px 10px;
    border-left: solid;
    border-color: #00bf98;
  }

  button {
    width: fit-content;
    min-width: 150px;
    height: 45px;
    margin: 20px 0;
    padding: 1vh 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #00bd97;
    border-radius: 10px;
    font-family: Barlow, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color: #fff;
    background-color: #00bd97;
    cursor: pointer;
    box-shadow: 0 9px 20px -7px rgb(0 0 0 / 73%);
  }

  button:hover {
    background: #00b08d;
  }

  button:active {
    background: #00b08d;
    box-shadow: none;
    outline: none;
    transform: scale(0.95);
  }

  svg {
    width: 15px;
    height: 15px;
  }
}
