.seeMoreLancamentos {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  color: rgb(0 189 151);

  p {
    color: rgb(0 189 151);
  }

  h3 {
    font-family: Khand, sans-serif;
    font-size: 1.5rem;
    color: rgb(0 189 151);
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
}
