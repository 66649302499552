@use '../../styles/global.scss';

.section {
  justify-content: flex-start;

  .imageBackground {
    width: 90%;
    height: 50%;
    position: absolute;
  }

  .card {
    width: 40%;
    height: 100%;
    margin-right: 3vw;
    padding: 3vh 3vw;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background-color: rgb(128 128 128 / 32.5%);

    .icons {
      figure {
        width: fit-content;
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          margin: 30px;
        }

        h3 {
          margin-bottom: 0;
          margin-left: 30px;
          font-size: clamp(0.8rem, 1.2vw, 1rem);
        }
      }
    }
  }

  .image {
    width: 50vw;
    margin: 10% 0 0 35%;
    position: absolute;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 30%);
  }
}

@media only screen and (max-width: 768px) {
  .section {
    justify-content: center;

    .imageBackground {
      display: none;
    }

    .card {
      width: 100%;
      margin-right: 0;

      h2,
      p {
        width: 100%;
      }

      h2 {
        text-align: center;
      }

      .icons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;

        div {
          margin: 20px;
        }
      }
    }

    .image {
      width: 95vw;
      margin: 2rem 0;
      position: relative;
    }
  }
}
